<template>
  <v-app>
    <v-navigation-drawer
      theme="dark"
      color="#202F35"
      permanent
      :mobile="true"
    >
      <div
        style="display: flex; align-content: center; align-items: center">

        <div style="padding: 4px 20px 20px 20px">
          <img src="public/integrex-logo.png" width="50" />
        </div>
        <div style="padding: 20px 0">
          <div style="font-weight: bold; font-size: 18px">DSP Integrex</div>
          <div style="font-size: 14px; opacity: 1; color: lightgreen">Online</div>
        </div>
      </div>
      <v-select
        :disabled="!selectedCompany"
        density="compact"
        :model-value="selectedCompany?.id"
        :items="companies"
        :item-props="(i)=>({ title: i.name, value: i.id })"
        variant="outlined"
        class="mx-4"
        @update:model-value="handleCompanyChange"
      />
      <v-list>
        <v-divider></v-divider>
        <v-list-item
          prepend-icon="mdi-devices"
          title="Workloads" to="/workloads"
        ></v-list-item>
        <!--        <v-list-item-->
        <!--          prepend-icon="mdi-view-dashboard"-->
        <!--          link-->
        <!--          title="Dashboard" to="/"-->
        <!--        >-->
        <!--        </v-list-item>-->
        <v-list-item
          prepend-icon="mdi-alert-circle-outline"
          title="Alerts" to="/alerts"
        >
          <template #append>
            <v-badge color="red" :dot="true"></v-badge>
          </template>
        </v-list-item>
        <!--
        <v-list-item
          prepend-icon="mdi-shield-star-outline"
          title="Cyber insurance" to="/cyber-insurance"
        >
        </v-list-item>
        -->
        <v-list-item
          prepend-icon="mdi-lightning-bolt-outline"
          title="Disaster recovery"
          to="/disaster-recovery"
        >
          <template #append v-if="!$disasterRecoveryEnabled">
            <v-icon size="xs" color="error">mdi-lock</v-icon>
          </template>
        </v-list-item>
        <v-list-item prepend-icon="mdi-chip" title="Integrations" to="/integrations"></v-list-item>
        <v-list-item
          prepend-icon="mdi-cog"
          title="Settings"
          to="/settings"
        >
        </v-list-item>
        <v-list-item
          prepend-icon="mdi-help-circle-outline"
          title="Help"
          to="/help"
        ></v-list-item>

      </v-list>
    </v-navigation-drawer>
    <v-main>
        <NuxtPage />
    </v-main>
    <app-footer />
  </v-app>
</template>

<script setup lang="ts">
import { type CompanyDto } from '~/entities/Company';

const $disasterRecoveryEnabled = useNuxtApp().$disasterRecoveryEnabled;
const $integrationsDB = useNuxtApp().$integrationsDB;

// const selectedCompany = ref<CompanyDto>();
const selectedCompany = useState<CompanyDto>('selectedCompany');
const companies = useState<CompanyDto[]>('companies');

const hasDoc = function <T>(row: { doc?: T }): row is Required<{ doc: T }> {
  return !!row.doc;
};

function handleCompanyChange(companyId:string) {
  const company = companies.value.find((c) => c.id === companyId);
  if (!company) return;
  selectedCompany.value = company;
}


onMounted(async () => {
  $integrationsDB.query<CompanyDto>('type_index/companies_by_id', { include_docs: true }).then((res) => {
    companies.value = res.rows.filter(hasDoc).map((row) => {
      return row.doc;
    }).sort((a, b) => {
      return a.sort === b.sort ? 0 : (a.sort > b.sort ? 1 : -1);
    }) as CompanyDto[];
    selectedCompany.value = companies.value[0];
  });
});

</script>
